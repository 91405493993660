<template>
    <v-container>
        <div>
            <s-crud title="Tipo pago por Zona"
                :filter="filter"
                searchInput
                add 
                remove
                @save="save($event)"
                edit
                :config="config">
                <template v-slot:filter>
                    <v-container>
                        <v-row justify="center">
                        <v-col cols="4">
                            <s-date
                            v-model="filter.DateBegin" 
                            label="Fecha Inicio">
                            </s-date>
                        </v-col>
                        <v-col cols="4">
                            <s-date
                            v-model="filter.DateEnd"  
                            label="Fecha Fin">
                            </s-date>
                        </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template scope="props">
                    <v-container>
                        <v-row>
                            <v-col>
                                <s-select-definition
                                label="Destino"
                                v-model="props.item.TypeZonLocation"
                                :def="1253">
                                </s-select-definition>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </s-crud>
        </div>
    </v-container>
</template>

<script>
import _sMaintenanceZonLocation from "@/services/FreshProduction/MaintenanceZonLocationService";

    export default {
        data(){
            return{
                filter: {DateBegin:null, DateEnd:null,},
                config:{
                    service: _sMaintenanceZonLocation,
                    model: {
                        ZliID:"ID",                      
                    },
                    headers: [
                        { text: "cod", value: "ZliID" },
                        { text: "Zona", value: "ZonLocationDescription" },
                        { text: "Fecha", value: "SecCreateString" },
                        // { text: "Tipo Cultivo", value: "TypeCropName" },
                        // { text: "Cultivo", value: "TypeCultiveName" },
                        // { text: "Variedad", value: "VrtName" },
                        // { text: "Descripción", value: "PrmDescription" },
                    ]
                },
            }
        },
        methods: {
            save(item) {

            item.ZliIgnore = 1;
            item.UsrUpdateID = this.$fun.getUserID();
            item.UsrCreateID = this.$fun.getUserID();

            item.save();
            },
        },
    }
</script>